import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import WebApp from '@twa-dev/sdk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownItem } from 'flowbite-react';


const SourcePage = ({ messageId }) => {
    const [source, setSource] = useState(null);
    const [isFormOpen, setFormOpen] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const textareaRef = useRef();

    const toggleForm = () => {
        setFormOpen(!isFormOpen);
      };
    
    const SourceType = {
        'massmedia': 'СМИ',
        'telegram': 'Telegram',
        'social_network': 'Социальная сеть'
    };
    
    const SourceCategory = {
        'industry': 'Отраслевой',
        'news': 'Новостной',
        'aggregator': 'Агрегатор',
        'regional': 'Региональный',
        'local': 'Локальный',
        'compromat': 'Компроматный'
    };
    
    const SourceRating = {
        'junk': 'Мусорный',
        'low': 'Низкий',
        'low_plus': 'Низкий (с охватами)',
        'neutral': 'Нейтральный',
        'neutral_plus': 'Нейтральный (с охватами)',
        'high': 'Авторитетный',
        'high_plus': 'Авторитетный (с охватами)'
    };

    const setSourceCategory = async (value) => {
        const response = await axios.post('/api/source/set', {
            message_id: messageId,
            category: value
        }, {
            headers: {
                "initData": WebApp.initData
            }
        });
    
        if (response.status === 200) {
            fetchSource();
        }
    };

    const setSourceRating = async (value) => {
        const response = await axios.post('/api/source/set', {
            message_id: messageId,
            rating: value
        }, {
            headers: {
                "initData": WebApp.initData
            }
        });

        if (response.status === 200) {
            fetchSource();
        }
    };

    const setSourceDescription = async (value) => {
        setSubmitting(true);
        const response = await axios.post('/api/source/set', {
          message_id: messageId,
          description: value
        }, {
            headers: {
                "initData": WebApp.initData
            }
        });
        if (response.status === 200) {
          fetchSource();
          setFormOpen(false);
        } else {
            alert('Произошла ошибка при сохранении описания.');
        }
        setSubmitting(false);
      };
    
    function CategoryDropdown(backgroundColor) {
        return (
            <Dropdown label="" dismissOnClick={true} className={`rounded-xl shadow-md focus:outline-none border-none outline-none`}
                renderTrigger={() => <FontAwesomeIcon icon={faPencil} size="xs" />}>
            {Object.entries(SourceCategory).map(([value, label]) => (
            <DropdownItem onClick={() => setSourceCategory(value)} key={value}><b>{label}</b></DropdownItem>
            ))}
          </Dropdown>
        );
    }

    function RatingDropdown(backgroundColor) {
        return (
            <Dropdown label="" dismissOnClick={true} className={`rounded-xl shadow-md focus:outline-none border-none outline-none`}
             renderTrigger={() => <FontAwesomeIcon icon={faPencil} size="xs" style={{color: WebApp.themeParams.text_color}} />
        }>
            {Object.entries(SourceRating).map(([value, label]) => (
              <DropdownItem onClick={() => setSourceRating(value)} key={value}><b>{label}</b></DropdownItem>
            ))}
          </Dropdown>
        );
    }

    const fetchSource = async () => {
    //     const response = {
    //         data: {
    //             "id": 1,
    //             "name": "РБК",
    //             "source_type": "massmedia",
    //             "category": "news",
    //             "rating": "high",
    //             "subscribers": 100500,
    //             "is_able_to_get_description": true
    //         }
    //     };
    //     setSource(response.data);
    // };
            try {
            const response = await axios.get(`/api/source/${messageId}`, {
                headers: {
                  "initData": WebApp.initData
                }
              });
            setSource(response.data);
        } catch (error) {
            console.error('Failed to fetch source:', error);
        }
    };
    
    useEffect(() => {
        fetchSource();
    }, [messageId]);

    if (!source) {
        return (
            <div className="flex justify-center items-center h-screen">
                <FontAwesomeIcon icon={faSpinner} spin />
            </div>
        ); 
    }

    return (
        <div 
            className="p-4"
            style={{ 
                backgroundColor: WebApp.themeParams.bg_color,
                color: WebApp.themeParams.text_color 
            }}
        >
            <div 
                className="max-w-md mx-auto rounded-xl shadow-md md:max-w-2xl m-4"
                style={{ 
                    backgroundColor: WebApp.themeParams.bg_color,
                    color: WebApp.themeParams.text_color
                }}
            >
                <div className="md:flex">
                    <div className="p-5">
                        <div className="uppercase tracking-wide text-sm font-semibold"
                            style={{color: WebApp.themeParams.accent_text_color}}
                        >{SourceType[source.source_type]}</div>
                        <p className="block mt-1 text-lg leading-tight font-medium"
                            style={{color: WebApp.themeParams.text_color}}
                        >{source.name}</p>
                        <div className="mt-4">
                            <span>Категория: </span><span className="pr-2"><b>{SourceCategory[source.category] || "не задано"}</b></span>
                            <CategoryDropdown backgroundColor={WebApp.themeParams.bg_color} />
                        </div>
                        <div className="mt-2">
                            <span>Рейтинг: </span><span className="pr-2"><b>{SourceRating[source.rating] || "не задано"}</b></span>
                            <RatingDropdown backgroundColor={WebApp.themeParams.bg_color} />
                        </div>
                        {source.subscribers && (
                            <div className="mt-2">
                                <span>Количество подписчиков: </span><span><b>{source.subscribers}</b></span>
                            </div>
                        )}
                        {source.is_able_to_get_description && (
                            <div className="mt-2">
                            <span>Описание:</span><br></br>
                            {!isFormOpen ? (
                              <span>
                                <b className="pr-2">{source.description || "не задано"}</b>
                                <FontAwesomeIcon 
                                  icon={faPencil} 
                                  size="xs" 
                                  style={{color: WebApp.themeParams.text_color}} 
                                  onClick={toggleForm}
                                />
                              </span>
                            ) : (
                            <form onSubmit={(e) => { e.preventDefault(); setSourceDescription(textareaRef.current.value); }}>
                                <textarea 
                                  type="text"
                                  className="border rounded-lg p-1 w-full"
                                  defaultValue={source.description || ""}
                                  ref={textareaRef}
                                  style={{ 
                                    marginBottom: '5px', 
                                    padding: '5px', 
                                    width: '100%',  // Установите ширину на 100% для использования всего доступного пространства
                                    maxWidth: '600px',  // Ограничьте максимальную ширину, чтобы форма не была слишком широкой на больших экранах
                                    backgroundColor: WebApp.themeParams.bg_color,
                                    color: WebApp.themeParams.text_color,
                                    borderColor: WebApp.themeParams.secondary_bg_color,
                                    outline: 'none'
                                }}
                                ></textarea>
                                <button 
                                    type="submit" 
                                    className={`font-bold py-2 px-4 rounded-lg`}
                                    disabled={isSubmitting}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        cursor: 'pointer',
                                        width: '100%',  // Установите ширину на 100% для использования всего доступного пространства
                                        maxWidth: '600px',  // Ограничьте максимальную ширину, чтобы форма не была слишком широкой на больших экранах,
                                        color: WebApp.themeParams.button_text_color,
                                        backgroundColor: WebApp.themeParams.button_color
                                    }}
                                >
                                    Сохранить
                                </button>
                              </form>
                            )}
                          </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SourcePage;