import React, { useEffect, useState } from 'react';
import axios from 'axios';
import WebApp from '@twa-dev/sdk'
import { Dropdown, DropdownItem } from 'flowbite-react';

const ReportPage = () => {
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/api/feed/all', {
        headers: {
          "initData": WebApp.initData
        }
      })
      .then(response => {
        setFeeds(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Замените этот div на ваш компонент лоадера
  }

  return (
    <div 
      className="p-4"
      style={{ 
          backgroundColor: WebApp.themeParams.bg_color,
          color: WebApp.themeParams.text_color 
      }}
    >
      <div 
          className="max-w-md mx-auto rounded-xl shadow-md md:max-w-2xl m-4"
          style={{ 
              backgroundColor: WebApp.themeParams.bg_color,
              color: WebApp.themeParams.text_color
          }}
      >
          <div className="md:flex">
              <div className="p-5">
                    <h1 className="text-2xl font-bold">Отчет</h1>
                    <Dropdown label="Тема">
                      {feeds.map(feed => (
                        <DropdownItem key={feed.name} value={feed.name}>{feed.name}</DropdownItem>
                      ))}
                    </Dropdown>
              </div>
          </div>
      </div>
    </div>
  );
}

export default ReportPage;